import { render, staticRenderFns } from "./PageForgot.vue?vue&type=template&id=5c917a87&scoped=true&"
import script from "./PageForgot.vue?vue&type=script&lang=js&"
export * from "./PageForgot.vue?vue&type=script&lang=js&"
import style0 from "./PageForgot.vue?vue&type=style&index=0&id=5c917a87&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c917a87",
  null
  
)

export default component.exports