<template>
  <div class="d-flex flex-column content landing">
    <div class="flex-grow-1 d-flex flex-md-row flex-column">
      <div class="w-50 promo position-relative d-none d-md-block">
        <div class="middle">
          <Logo class="middle__logo" />
        </div>
      </div>

      <div class="w-50 h-100 position-md-relative">
        <div class="mobile-auth">
          <div class="mobile-auth__hero d-md-none">
            <div class="mobile-auth__logo-wrapper">
              <Logo class="mobile-auth__logo" />
            </div>
          </div>

          <div class="mobile-auth__title">
            <span class="mobile-auth__font mobile-auth__font--title">
              {{ $t("general.auth.reset-password.title") }}
            </span>
          </div>

          <div class="mobile-auth__content">
            <form novalidate class="form" @submit.prevent="submitForm">
              <CInput
                type="email"
                name="email"
                input-mode="email"
                v-model="email"
                :label="$t('general.auth.signUp.form.email')"
              />
              <!--        :rules="'required|email'"-->

              <CButton
                type="submit"
                variant="primary"
                class="w-100 mb-3"
                :disabled="hasErrors"
              >
                {{ $t("general.password-reset") }}
              </CButton>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="border-top p-3 d-flex small align-items-center justify-content-center"
    >
      <CLink to="/login">{{ $t("general.login") }}</CLink>
      <i class="bi-dot" />
      <CLink to="/signup"> {{ $t("general.auth.signUp.button.base") }}</CLink>
    </div>
  </div>
</template>

<script>
import CButton from "@/features/ui/СButton.vue";
import CLink from "@/features/ui/CLink.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import CInput from "@/features/ui/СInput.vue";
import Logo from "@/assets/svg/logo.svg";
export default {
  name: "PageForgot",
  components: { Logo, CInput, CLink, CButton },
  mixins: [validationError, waitRequest],
  data() {
    return {
      email: "",
    };
  },
  computed: {
    hasErrors() {
      return Boolean(this.errors.items.length);
    },
  },
  methods: {
    closePopupCallback() {
      this.$popup.close();
      this.$router.push({ name: "login" });
    },

    openPopup() {
      this.$popup.open("ResetPasswordPopup", {
        callback: this.closePopupCallback,
      });
    },
    async submitForm() {
      const isValid = await this.$validator.validate("email");

      if (!isValid || this.hasErrors || this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$api(
          "post",
          process.env.VUE_APP_API_URL + "/v1/auth/recovery-password/send",
          {
            email: this.email,
          },
          () => {
            this.openPopup();
          },
          () => {},
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/vendors/_variables.scss";
.promo {
  background: $app-blue;
  color: $white;
  .middle {
    width: 100%;
    max-width: 344px;
    text-align: left;

    &__logo {
      width: 50%;
      margin-bottom: em(16);
    }
    h2 {
      font-weight: normal;
    }
  }
}

.main {
  width: 344px;
  text-align: left;

  &__logo {
    width: 50%;
    margin-bottom: em(16);
  }
}

.mobile-auth {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: center;
  }

  &__hero {
    background-image: url("/img/backgrounds/auth-login-background.webp");
    background-color: $black;
    height: em(160);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__logo-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 1px);
    height: em(61);
    width: em(204);
  }

  &__title {
    margin-top: em(20);
    margin-bottom: em(25);
  }

  &__title,
  &__content {
    padding-left: em(25);
    padding-right: em(25);
  }

  &__font {
    &--title {
      color: #000;
      font-family: $font-default;
      font-size: em(24);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

@include media-breakpoint-down(sm) {
  .w-50 {
    width: 100% !important;
  }
  .main {
    width: 90%;
  }
}
</style>
